import React, { Component } from 'react';
import { Link } from 'gatsby';
import loadable from '@loadable/component';
import styled from 'styled-components';

const InfoPage = loadable(() => import('../components/InfoPage'));
const ContentBox = loadable(() =>
  import('../components/sub-components/contentBox')
);

const LocalContentBox = styled(ContentBox)`
  padding: ${props => (props.roomyLayout ? '0 0 30px 0' : '0 30px 30px 30px')};
  h2 {
    font-weight: 500;
    font-size: 20px;
  }
  h3,
  h4 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
    text-align: left;
    text-transform: unset;
    letter-spacing: unset;
  }
  p {
    font-size: 16px;
    line-height: 23px;
  }
  strong {
    font-weight: 600;
  }
`;

class MoreInfo extends Component {
  render() {
    return (
      <InfoPage
        title="Jennifer Mone Hill Artist - More Information"
        description="How to purchase Jennifer Mone Hill original art. Easy purchase process.  Shipping and Delivery."
        keywords={[
          'buy art',
          'buy Jennifer Moné Hill art',
          'Jen Hill Artist',
          'Jennifer Mone Hill',
          'Jen Hill',
          'Jen Hill Artist',
          'how to purchase',
          'corporate art',
          'interior art',
          '2D art',
          'paintings',
          'buy paintings',
          'buy modern art',
          'buy abstract art',
          'availability',
          'commission art',
          'one of a kind',
          'original art',
          'buy original art',
        ]}
        imageLink="/works/Roseate"
        imgSrc="Roseate-by-Jennifer-Mone-Hill-800.jpg"
        imgSrc2x="Roseate-by-Jennifer-Mone-Hill-1600.jpg"
        alt="Roseate by Jennifer Moné Hill"
        customImageWidth={30}
        roomyLayout
      >
        <LocalContentBox roomyLayout={true}>
          <h2>More Info</h2>
          <h3>Original Art</h3>
          <p>
            Level up your space with original art. Living with original art that
            you love not only elevates your space, but it also elevates your
            mood, relieving stress, promoting relaxation, and improving
            thinking. Original art is the perfect way to inspire, express
            yourself, or celebrate life’s milestones. It’s the finishing touch
            that completes your space.
            <br />
            <br />
            Purchasing art directly from the artist is the most satisfying way
            to go. You’ll feel good knowing you’re helping support the arts in
            the best way possible. We’ll feel good knowing we made a difference
            in your life creating the space you love that makes you feel good
            too.
            <br />
            <br />
            Each work of art is unique, one of a kind, original artwork by
            Jennifer Moné Hill. Use the hover to zoom feature to see the unique
            details up-close.
            <br />
            <br />
            Private viewing of the work prior to purchasing is available. Email
            to set up an appointment. Masks and social distancing protocols
            required.
          </p>
          <h3>Easy Purchase Process</h3>
          <p>
            From the <Link to="/#works">Shop Art</Link> page, click the artwork
            you’re interested in purchasing. Each artwork has a button to
            purchase. Just add to cart, and checkout with your credit card.
          </p>
          <h3>Shipping &amp; Delivery</h3>
          <p>
            <strong>Local: </strong>Local purchases may be hand delivered, or
            picked up in Danbury, CT.
            <br />
            <strong>Free delivery</strong> for sizes up to 72x40”{' '}
            <strong>
              for our neighbors in Fairfield County, Westchester County, and
              Putnam County,
            </strong>{' '}
            automatically calculated in your cart on checkout based on zip code.
            We’ll contact you after purchase to set up a delivery time that
            works for you. Minimum purchase $500 for free delivery.
            <br />
            <br />
            <strong>NYC:</strong> In New York City shipping/delivery is 10% or
            $98 whichever is lower, for sizes up to 72x40”.
            <br />
            <br />
            <strong>Everywhere else: </strong>
            Outside of our local area shipping will be 10% of the purchase price
            to help cover the cost of FedEx Ground and careful packaging.
          </p>
          <h3>Protective Finish</h3>
          <p>
            Acrylic paintings are treated with a varnish finish to protect your
            art from UV, dust, and life’s mishaps. Feel free to gently clean
            your painting with a soft microfiber cloth. Resin paintings have a
            hard glass like finish that may also be cleaned off with a soft
            microfiber cloth.
          </p>
          <h3>Ready to Hang</h3>
          <p>
            Paintings come wired and ready to hang. Protective felt pads on the
            back keep the canvas from scuffing your wall during installation.
            Paintings come unframed, with painted sides. We find that most
            collectors like the modern presentation and seeing the drips down
            the side of the canvas. You can feel the action of Jennifer’s
            artistic process. If you wish to frame your art, please do.
          </p>
          <h3>Signed by the Artist</h3>
          <p>
            Paintings are signed by Jennifer Moné Hill on the back of the work.
          </p>
          <h3>Installation</h3>
          <p>
            Let us know if you need help installing your new art. We can help.
          </p>
          <h3>Questions?</h3>
          <p>
            Please{' '}
            <a href="mailto:jmonehill@gmail.com?subject=Pre-Puchase Inquiry">
              email
            </a>{' '}
            any pre-purchase inquiries directly to Jennifer, about the painting
            in which you are interested.
          </p>
        </LocalContentBox>
      </InfoPage>
    );
  }
}

export default MoreInfo;
